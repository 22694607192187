import React from "react";
import styled from "styled-components";
import loadable from "@loadable/component"
import "../../styles/globals.css";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation, Trans, Link } from "gatsby-plugin-react-i18next";

import { MetaComponent } from "../../components";
import {
  Button,
  SassyText,
  Title,
  Section,
  Text,
  Accordion,
  SectionWrapper,
  HeroSection,
  Waves,
  StyledLink as GatsbyLink,
} from "../../components/core";
import { OnDesktop, OnMobile } from "../../components/DesktopMobileComps";
import { useMixpanel } from "../../context/MixpanelContext";
import useHindiLangFont from "../../hooks/useHindiLangFont";
import { LeadFormProvider } from "../../context/LeadFormContext";

import FireImage from "../../assets/images/fire_art.svg";
import GetQuote from "../../assets/images/get_quote.svg";
import CompetitiveQuotes from "../../assets/images/competitive_quotes.svg";
import ModifyCover from "../../assets/images/modify_cover.svg";
import ClickArt from "../../assets/images/click.svg";
import HelpSection from "../../components/HelpSection";
import RightArrow from "../../assets/images/right_arrow.svg";
import ChillArt from "../../assets/images/chill.svg";

const Footer = loadable(() => import("../../components"), {
  resolveComponent: (components) => components.Footer,
});
const LeadForm = loadable(() => import("../../components/LeadForm"));
const Navbar = loadable(() => import("../../components"), {
  resolveComponent: (components) => components.Navbar,
});
const ThreatEvents = loadable(() => import("../../components/ThreatEvents"))

const Fire = () => {
  const {hindiBold, hindiBlack, hindiRegular} = useHindiLangFont()
  const {t} = useTranslation(['fire', 'common'])
  const mixpanel = useMixpanel();
  const [isQuoteModalOpen, setIsQuoteModalOpen] = React.useState(false);

  const handleQuoteModalOpen = () => {
    setIsQuoteModalOpen(true);
  };

  const handleQuoteModalClose = () => {
    setIsQuoteModalOpen(false);
  };

  const handleGetQuoteClick = (mxEventName = "") => {
    if (mxEventName) mixpanel.track(mxEventName);
    handleQuoteModalOpen();
  };

  const trackMixpanel = (event) => {
    mixpanel.track(event);
  };

  return (
    <>
      <LeadFormProvider>
        {isQuoteModalOpen ? <LeadForm onModalClose={handleQuoteModalClose} /> : null}
      </LeadFormProvider>
      <MetaComponent
        title="Fire Insurance for your Business - Verak"
        description="Hassle free Fire and Allied Perils Insurance. Protect your business from fire, explosion, floods, riots, and much more. Get a quote."
        image="fire"
        url="fire"
      />
      <Navbar active="products" />
      <Section background="rgba(255, 238, 227, 0.5)">
        <Waves />
        <SectionWrapper>
          <HeroSection className="display-flex flex-direction-row">
            <HeadingContainer>
              <Title
                fontSize="64px"
                lineHeight="76px"
                style={{ paddingBottom: "50px" }}
                className={hindiBlack}
              >
                <Trans i18nKey="HeroSection.Title">
                  Fuss-free <br />
                  <SassyText>Property Insurance</SassyText>
                  <br />
                  for your business
                </Trans>
              </Title>
              <HeroContainer>
                <FireImage className="fire-hero-mobile" />
              </HeroContainer>

              <Text style={{ paddingBottom: "50px" }} className={hindiRegular}>
                {t('HeroSection.Subtitle')}
              </Text>
              <Button
                wide
                label={t('CTALabels.GetAQuote')}
                onClick={() =>
                  handleGetQuoteClick("Clicked-Top - 'Get a Quote' - Fire LP")
                }
              />
            </HeadingContainer>
            <HeroContainer>
              <StaticImage 
                src="../../assets/images/fire_graphic.webp"
                alt="Fire Insurance for your Business"
                className="fire-hero-desktop"
              />
            </HeroContainer>
          </HeroSection>
        </SectionWrapper>
      </Section>
      <Section background="#fff">
        <SectionWrapper>
          <Text fontSize="20px" lineHeight="30px" mfontSize="16px" style={{textAlign: "center"}}>
            {t('ThreatsSectionDesc')}
          </Text>
          <ThreatEvents />
        </SectionWrapper>
      </Section>
      <Section background="#ffffff">
        <SectionWrapper>
          <Title fontSize="36px" fontWeight="bold" className={hindiBold}>
            {t('PoliciesSection.Title')}
          </Title>
          <Container>
            <StyledCard
              to="/fire/bsus"
              onClick={() => trackMixpanel("Clicked learn more BSUS")}
            >
              {/* <BSUSSmall className="icon" /> */}
              <div className="icon" style={{width: 'min(100px, 100%)'}}>
                <StaticImage src={"../../assets/images/bsus_small.webp"} alt="bsus-small" />
              </div>
              <h5 className={hindiBold}>{t('PoliciesSection.Policies.BSUS.Name')}</h5>
              <p className={hindiRegular}>
                <Trans i18nKey="PoliciesSection.Policies.BSUS.Desc">
                  Bharat Sookshma Udyam Suraksha (BSUS) policy insures enterprises
                  for which the 
                  <strong className={hindiBold}>
                    total value of risk at one location is up to ₹5 crore.
                  </strong>
                </Trans>
              </p>
              <StyledLink className={hindiRegular}>
                {t('PoliciesSection.Policies.BSUS.LearnMore')}
                <RightArrow className="arrow" />
              </StyledLink>
            </StyledCard>
            <StyledCard
              to="/fire/blus"
              onClick={() => trackMixpanel("Clicked learn more BLUS")}
            >
              {/* <BLUSSmall className="icon" /> */}
              <div className="icon" style={{width: 'min(230px, 100%)'}}>
                <StaticImage src={"../../assets/images/blus_small.webp"} alt="blus-small" />
              </div>
              <h5 className={hindiBold}>{t('PoliciesSection.Policies.BLUS.Name')}</h5>
              <p className={hindiRegular}>
                <Trans i18nKey="PoliciesSection.Policies.BLUS.Desc">
                  Bharat Laghu Udyam Suraksha (BLUS) covers enterprises for which
                  the 
                  <strong className={hindiBold}>
                    total value of risk at one location exceeds ₹5 crore, but not
                    ₹50 crore.
                  </strong>
                </Trans>
              </p>
              <StyledLink className={hindiRegular}>
                {t('PoliciesSection.Policies.BLUS.LearnMore')}
                <RightArrow className="arrow" />
              </StyledLink>
            </StyledCard>
            <StyledCard
              to="/fire/sfsp"
              onClick={() => trackMixpanel("Clicked learn more SFSP")}
            >
              {/* <SFSPSmall className="icon" /> */}
              <div className="icon" style={{width: 'min(280px, 100%)'}}>
                <StaticImage src={"../../assets/images/sfsp_small.webp"} alt="sfsp-small" />
              </div>
              <h5 className={hindiBold}>{t('PoliciesSection.Policies.SFSP.Name')}</h5>
              <p className={hindiRegular}>
                <Trans i18nKey="PoliciesSection.Policies.SFSP.Desc">
                  Standard Fire and Special Perils (SFSP) insures enterprises for
                  which the 
                  <strong className={hindiBold}>
                    total value of risk at one location exceeds ₹50 crore.
                  </strong>
                </Trans>
              </p>
              <StyledLink className={hindiRegular}>
                {t('PoliciesSection.Policies.SFSP.LearnMore')}
                <RightArrow className="arrow" />
              </StyledLink>
            </StyledCard>
          </Container>
        </SectionWrapper>
      </Section>
      <Section background="#FFF7F1">
        <SectionWrapper>
          <Title fontSize="36px">{t('WhyChooseUs.Title')}</Title>
          <Container>
            <IconContainer>
              <GetQuote />
            </IconContainer>
            <Text
              fontSize="24px"
              mfontSize="16px"
              fontWeight="bold"
              color="#03045E"
              style={{ marginBottom: "24px" }}
              className={hindiBold}
            >
              {t('WhyChooseUs.SeamLessExp')}
            </Text>
          </Container>
          <Container>
            <IconContainer>
              <CompetitiveQuotes />
            </IconContainer>
            <Column>
              <Text
                fontSize="24px"
                mfontSize="16px"
                fontWeight="bold"
                color="#03045E"
                style={{ marginBottom: "24px" }}
                className={hindiBold}
              >
                {t('WhyChooseUs.CompetitiveQuotes')}
              </Text>
              <Button
                wide
                label={t('CTALabels.GetAQuote')}
                onClick={() =>
                  handleGetQuoteClick(
                    "Clicked-Middle - 'Get a Quote' - Fire LP"
                  )
                }
                className={hindiBold}
              />
            </Column>
          </Container>
          <Container>
            <IconContainer>
              <ModifyCover />
            </IconContainer>
            <div>
              <Text
                fontSize="24px"
                mfontSize="16px"
                fontWeight="bold"
                color="#03045E"
                style={{ marginBottom: "24px" }}
                className={hindiBold}
              >
                {t('WhyChooseUs.ModifyCover')}
              </Text>
              <OnMobile>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://wa.me/919769161630?text=Hey"
                >
                  <Button
                    wide
                    type="whatsapp"
                    label={t('CTALabels.SayHiOnWA')}
                    className={hindiBold}
                    onClick={() =>
                      mixpanel.track("Clicked-Top - 'WA Chat' - Fire LP")
                    }
                  />
                </a>
              </OnMobile>
              <OnDesktop>
                <Button
                  wide
                  type="chatbot"
                  label={t('CTALabels.SayHiOnWeb')}
                  onClick={() => document.querySelector('#ymDivBar')?.click()}
                  className={hindiBold}
                />
              </OnDesktop>
            </div>
          </Container>
          <Container>
            <IconContainer>
              <ClickArt />
            </IconContainer>
            <Column>
              <Text
                fontSize="24px"
                mfontSize="16px"
                fontWeight="bold"
                color="#03045E"
                style={{ marginBottom: "24px" }}
                className={hindiBold}
              >
                {t('WhyChooseUs.QuickClaims')}
              </Text>
              <GatsbyLink to="/claims">
                <Button
                  onClick={() => trackMixpanel("Clicked Visit Claims Page")}
                  type="secondary"
                  label={t('CTALabels.VisitClaims')}
                  wide
                  className={hindiBold}
                />
              </GatsbyLink>
            </Column>
          </Container>
        </SectionWrapper>
      </Section>
      <Section background="#fff">
        <SectionWrapper> 
          <Title style={{ paddingBottom: "50px" }} className={hindiBold}>{t('AboutFireIns.Title')}</Title>
          <Column>
            <Accordion
              title={t('AboutFireIns.Accordion1.Question')}
              content={
                <>
                  {t('AboutFireIns.Accordion1.Answer.MisfortuneCanHappen')}
                  <br />
                  <ul>
                    <li>{t('AboutFireIns.Accordion1.Answer.Misfortune1')}</li>
                    <li>{t('AboutFireIns.Accordion1.Answer.Misfortune2')}</li>
                    <li>{t('AboutFireIns.Accordion1.Answer.Misfortune3')}</li>
                  </ul>
                  {t('AboutFireIns.Accordion1.Answer.HowFireInsProtectsYou')}
                </>
              }
              titleClassName={hindiBold}
              contentClassName={hindiRegular}
            />
            <Accordion
              title={t('AboutFireIns.Accordion2.Question')}
              content={
                <>
                  {t('AboutFireIns.Accordion2.Answer.WhatIsCovered')}
                  <br />
                  {t('AboutFireIns.Accordion2.Answer.CoveredItems.Title')}
                  <ul>
                    <li>{t('AboutFireIns.Accordion2.Answer.CoveredItems.Fire')}</li>
                    <li>{t('AboutFireIns.Accordion2.Answer.CoveredItems.Explosion')}</li>
                    <li>{t('AboutFireIns.Accordion2.Answer.CoveredItems.Lightning')}</li>
                    <li>{t('AboutFireIns.Accordion2.Answer.CoveredItems.Aircraft')}</li>
                    <li>
                      {t('AboutFireIns.Accordion2.Answer.CoveredItems.ByThirdParty')}
                    </li>
                    <li>
                      {t('AboutFireIns.Accordion2.Answer.CoveredItems.NaturalDisaster')}
                    </li>
                    <li>
                      {t('AboutFireIns.Accordion2.Answer.CoveredItems.RSMD')}
                    </li>
                    <li>
                      {t('AboutFireIns.Accordion2.Answer.CoveredItems.Leakage')}
                    </li>
                  </ul>
                  {t('AboutFireIns.Accordion2.Answer.ExactCoverageVaries')}
                </>
              }
              titleClassName={hindiBold}
              contentClassName={hindiRegular}
            />
            <Accordion
              title={t('AboutFireIns.Accordion3.Question')}
              content={t('AboutFireIns.Accordion3.Answer')}
              titleClassName={hindiBold}
              contentClassName={hindiRegular}
            />
          </Column>
        </SectionWrapper>
      </Section>
      <HelpSection />
      <Section background="#ffffff" height="600px">
        <SectionWrapper>
          <StressContainer>
            <OnDesktop style={{width: 'auto', marginRight: '2rem'}}>
              <StaticImage 
                src="../../assets/images/stress_free_fire_insurance.png"
                alt="fire-insurance-stress-free"
              />
            </OnDesktop>
            <ColumnFlex>
              <Title className={hindiBlack}>
                <Trans i18nKey="StressFreeIns">
                  Ready to take the
                  <br />
                  <SassyText className={hindiBlack}>stress out of property insurance?</SassyText>
                </Trans>
              </Title>
              <div className="on-desktop" style={{marginTop: "6rem"}}>
                <Button
                  wide
                  label={t('CTALabels.GetAQuote')}
                  onClick={() =>
                    handleGetQuoteClick(
                      "Clicked-Down - 'Get a Quote' - Fire LP"
                    )
                  }
                  className={hindiBold}
                />
              </div>
            </ColumnFlex>
            <OnMobile>
              <ChillArt className="umbrella-icon" />
            </OnMobile>
            <div className="on-mobile">
              <Button
                wide
                label={t('CTALabels.GetAQuote')}
                onClick={() =>
                  handleGetQuoteClick("Clicked-Down - 'Get a Quote' - Fire LP")
                }
                className={hindiBold}
              />
            </div>
          </StressContainer>
        </SectionWrapper>
      </Section>
      <Footer />
    </>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: {
          in: ["fire", "index", "common", "navbar", "footer", "getQuoteModal", "helpSection", "threat-events"]
        }, 
        language: {eq: $language}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    align-items: center;
    justify-content: center;
  }
`;

const StressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: column;

    button {
      margin-top: 30px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .umbrella-icon {
    margin-top: 20px;
    margin-left: 80px;
    @media screen and (max-width: 768px) {
      width: 250px;
      height: 250px;
      margin-left: 0;
    }
  }
`;

const StyledCard = styled(Link)`
  width: 33%;
  height: 450px;
  margin: 0 8px;
  background: #ffffff;
  border: 0.5px solid rgba(139, 142, 209, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
    height: fit-content;
  }

  h5 {
    padding: 10px 40px;
    color: #4d4d4d;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 1px;

    @media screen and (max-width: 768px) {
      font-size: 26px;
    }
  }

  p {
    margin-top: 24px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: center;
    color: #6d6d6d;
    padding: 0px 40px;

    @media screen and (max-width: 768px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .icon {
    margin: 30px 0px;
  }
`;

const StyledLink = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 12px;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #03045e;
  font-weight: bold;
  margin: auto auto 24px auto;
  background: #fff2ea;
  border-radius: 9px;

  .arrow {
    margin-left: 8px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  @media screen and (max-width: 768px) {
    align-items: center;
  }
`;

const HeroContainer = styled.div`
  .fire-hero-mobile {
    width: 0px;
    height: 0px;
    visibility: hidden;

    @media screen and (max-width: 768px) {
      margin-right: auto;
      margin-left: auto;
      visibility: visible;
      width: 230px;
      height: 250px;
    }
  }

  .fire-hero-desktop {
    width: max(450px, 100%);
    height: auto;

    @media screen and (max-width: 768px) {
      width: 0px;
      height: 0px;
      visibility: hidden;
    }
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;

    p {
      padding-bottom: 0px !important;
    }

    button {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const IconContainer = styled.div`
  width: 200px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 50px 0px;

  .space-left {
    padding-left: 35px;
  }

  .space-right {
    padding-right: 35px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export default Fire;
